<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  @click="batchAudit(1,'审核')"
                  class="mr-1"
                  size="sm"
                  v-if="user.role_id==14"
              >
                <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning"
                  @click="batchAudit(2,'驳回')"
                  class="mr-1"
                  size="sm"
                  v-if="user.role_id==14"
              >
                <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量驳回</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="selectAllRows"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                />
                <span class="align-middle">全部勾选</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="clearSelected"
                  class="mr-1"
                  size="sm"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">清空勾选</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.cNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="PO单号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.orderNo"
                      name="orderNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态"
                    label-for="invoicing_status"
                    label-size="sm"
                >
                  <v-select
                      id="qtyIsZero"
                      :options="getCodeOptions('purchase_close_check_status')"
                      v-model="condition.status"
                      name="stocktaking_status"
                      class="select-size-sm"
                      placeholder="请选择状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="success"
          select-mode="multi"
          @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <!--          <b-link-->
          <!--              :to="{ name: 'apps-purchaseclose-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->

          <!--          </b-link>-->
          #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ toDate(data.item.update_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <!-- Column: State -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('purchase_close_check_status', data.item.status)}`"
          >
            {{ getCodeLabel("purchase_close_check_status", data.item.status) }}
          </b-badge>
        </template>

        <template #cell(memo)="data">
          <div>
            {{ isEmpty(data.item.memo) ? '' : data.item.memo.substring(0, 10) }}
            <feather-icon
                v-if="!isEmpty(data.item.memo)&&data.item.memo.length > 10"
                icon="EyeIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '<br>'+data.item.memo+
              '</div>'"
                variant="success"
            />
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="changeStatus(data.item,1,'审核通过')" v-if="data.item.status ==0&&user.role_id==14">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="changeStatus(data.item,2,'驳回')" v-if="data.item.status ==0&&user.role_id==14">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import purchasecloseUseList from './purchasecloseUseList'
import purchasecloseStore from './purchasecloseStore'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('purchaseclose/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData();
    },
    resetCondition() {
      this.condition = {}
      this.refetchData()
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.refListTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refListTable.clearSelected()
    },
  },
  data() {
    return {
      advanced_search_modal: false,
      table: [],
      user: {},
      selected: []
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseclose')) store.registerModule('purchaseclose', purchasecloseStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseclose')) store.unregisterModule('purchaseclose')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const changeStatus = function (params, status, information) {
      if (confirm('是否' + information)) {
        store.dispatch('purchaseclose/changeStatus', {id: params.c_id, status: status}).then(res => {
          if (res.data.code == 0) {
            toast.success(res.data.data)
          } else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }
    }

    const batchAudit = function (status, information) {
      if (confirm("是否批量" + information)) {
        if (this.selected.length == 0) {
          toast.error('请选择数据!')
          return false
        }
        for (let i = 0; i < this.selected.length; i++) {
          if (status == 1 && this.selected[i].status != 0) {
            toast.error('只有待审核状态才能审核通过!')
            return false
          }
          if (status == 2 && this.selected[i].status != 0) {
            toast.error('只有待审核状态才能驳回!')
            return false
          }
        }
        var promise = Promise.resolve()

        for (let i = 0; i < this.selected.length; i++) {
          promise = promise.then(() => {
            return new Promise((resolve, reject) => {
              store.dispatch('purchaseclose/changeStatus', {
                id: this.selected[i].c_id, status: status
              }).then(res => {
                if (res.data.code == 1) {
                  toast.error(res.data.data)
                }
                resolve()
              })
            })
          })
        }

        promise.then(() => {
          refetchData()
          toast.success("操作成功!")
        })
      }

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = purchasecloseUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      isEmpty,

      advanced_search,
      changeStatus,
      batchAudit,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
